import {
  type ILeadBasketProducts,
  type ILeadSessions,
  type IProductDownloaded,
} from '@mahawi/eshop-common';
import { Store } from 'redux';
import { Socket as SocketIO } from 'socket.io-client';

import {
  ShowNotificationCallback,
  type ShowNotificationMessage,
  SOCKET_ACTIONS,
} from '../admin-types';
import {
  CATEGORIES_ACTION,
  type ICategoryLoadAllResponse,
} from './reducers/categories/types';
import { DOWNLOADER_ACTION } from './reducers/downloader/types';
import {
  FEATURE_FLAGS_ACTION,
  type ILoadAllResponse as FeatureFlagsILoadAllResponse,
} from './reducers/feature-flags/types';
import { MESSENGER_ACTION } from './reducers/messenger/types';
import {
  ordersLoadAllOrderStatusTypeCodes,
  ordersLoadAllReviewStatusTypeCodes,
} from './reducers/orders/actions';
import {
  type ILoadAllOrderStatusTypeCodesResponse,
  type ILoadAllReviewStatusTypeCodesResponse,
} from './reducers/orders/types';
import {
  type ILoadAllTypesResponse,
  PROPERTIES_ACTION,
} from './reducers/properties/types';
import { sendRaw } from './reducers/send-request';
import { type ILoadAllResponse, TAGS_ACTION } from './reducers/tags/types';

export default class Socket {
  public constructor(
    private readonly socket: SocketIO,
    private readonly store: Store,
  ) {}

  public async requests(): Promise<void> {
    const requestInterval: NodeJS.Timeout = setInterval((): void => {
      if (this.socket.connected) {
        clearInterval(requestInterval);

        sendRaw(this.socket, ordersLoadAllOrderStatusTypeCodes()).then(
          (
            response: ILoadAllOrderStatusTypeCodesResponse,
          ): ILoadAllOrderStatusTypeCodesResponse =>
            this.store.dispatch(response),
        );

        sendRaw(this.socket, ordersLoadAllReviewStatusTypeCodes()).then(
          (
            response: ILoadAllReviewStatusTypeCodesResponse,
          ): ILoadAllReviewStatusTypeCodesResponse =>
            this.store.dispatch(response),
        );

        sendRaw(this.socket, {
          type: TAGS_ACTION.LOAD_ALL,
        }).then(
          (response: ILoadAllResponse): ILoadAllResponse =>
            this.store.dispatch(response),
        );

        sendRaw(this.socket, {
          type: PROPERTIES_ACTION.LOAD_ALL_TYPES,
        }).then(
          (response: ILoadAllTypesResponse): ILoadAllTypesResponse =>
            this.store.dispatch(response),
        );

        sendRaw(this.socket, {
          type: FEATURE_FLAGS_ACTION.LOAD_ALL,
        }).then(
          (
            response: FeatureFlagsILoadAllResponse,
          ): FeatureFlagsILoadAllResponse => this.store.dispatch(response),
        );

        sendRaw(this.socket, {
          type: CATEGORIES_ACTION.LOAD_ALL,
        }).then(
          (response: ICategoryLoadAllResponse): ICategoryLoadAllResponse =>
            this.store.dispatch(response),
        );
      }
    }, 500);
  }

  public async subscribe(): Promise<void> {
    this.socket.on('messenger.message', (message): void => {
      this.store.dispatch({
        type: MESSENGER_ACTION.CUSTOMER_SEND_MESSAGE,
        ...message,
      });
    });

    this.socket.on(
      MESSENGER_ACTION.LEAD_SESSIONS,
      (message: ILeadSessions): void => {
        this.store.dispatch({
          type: MESSENGER_ACTION.LEAD_SESSIONS,
          ...message,
        });
      },
    );

    this.socket.on(
      MESSENGER_ACTION.LEAD_BASKET_PRODUCTS,
      (message: ILeadBasketProducts): void => {
        this.store.dispatch({
          type: MESSENGER_ACTION.LEAD_BASKET_PRODUCTS,
          ...message,
        });
      },
    );

    this.socket.on('event.message', (message): void => {
      if (message.payload.event.code === 'TICK') {
        this.store.dispatch({
          type: MESSENGER_ACTION.CUSTOMER_TICK,
          ...message,
        });
      }
    });

    this.socket.on(
      'showNotification',
      (message: ShowNotificationMessage): void => {
        this.store.dispatch({
          type: 'showNotification',
          ...message,
        });
      },
    );
  }

  public subscribeEventUniversalDownloaderProductResponse(): void {
    this.socket.on(
      'event.universal-downloader.product.response',
      (message: IProductDownloaded): void =>
        this.eventUniversalDownloaderProductResponseCallback(message),
    );
  }

  public unsubscribeEventUniversalDownloaderProductResponse(): void {
    this.socket.off(
      'event.universal-downloader.product.response',
      (message: IProductDownloaded): void =>
        this.eventUniversalDownloaderProductResponseCallback(message),
    );
  }

  public subscribeEventShowNotification(cb: ShowNotificationCallback): void {
    this.socket.on(
      SOCKET_ACTIONS.SHOW_NOTIFICATION,
      (message: ShowNotificationMessage): void => cb(message),
    );
  }

  public unsubscribeEventShowNotification(): void {
    this.socket.off(SOCKET_ACTIONS.SHOW_NOTIFICATION);
  }

  private eventUniversalDownloaderProductResponseCallback(
    message: IProductDownloaded,
  ): void {
    this.store.dispatch({
      type: DOWNLOADER_ACTION.UPDATE_PRODUCT,
      product: message,
    });
  }
}
