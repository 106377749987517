import { combineReducers, type Dispatch } from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

import Brand from './brand/reducer';
import Categories from './categories/reducer';
import Category from './category/reducer';
import ComparisonPortal from './comparison-portal/reducer';
import Config from './config/reducer';
import Countries from './countries/reducer';
import Country from './country/reducer';
import Currency from './currency/reducer';
import Dashboard from './dashboard/reducer';
import Discount from './discount/reducer';
import Downloader from './downloader/reducer';
import Ebay from './ebay/reducer';
import FeatureFlags from './feature-flags/reducer';
import Language from './language/reducer';
import Messenger from './messenger/reducer';
import Order from './order/reducer';
import Orders from './orders/reducer';
import Product from './product/reducer';
import Products from './products/reducer';
import Properties from './properties/reducer';
import Property from './property/reducer';
import Report from './report/reducer';
import Search from './search/reducer';
import Tags from './tags/reducer';
import User from './user/reducer';
import Warehouse from './warehouse/reducer';

export interface RootInterface {
  brand: typeof Brand;
  categories: typeof Categories;
  category: typeof Category;
  ComparisonPortal: typeof ComparisonPortal;
  config: typeof Config;
  countries: typeof Countries;
  country: typeof Country;
  currency: typeof Currency;
  dashboard: typeof Dashboard;
  discount: typeof Discount;
  dispatch: Dispatch;
  downloader: typeof Downloader;
  ebay: typeof Ebay;
  featureFlags: typeof FeatureFlags;
  language: typeof Language;
  messenger: typeof Messenger;
  order: typeof Order;
  orders: typeof Orders;
  product: typeof Product;
  products: typeof Products;
  properties: typeof Properties;
  property: typeof Property;
  report: typeof Report;
  search: typeof Search;
  tags: typeof Tags;
  user: typeof User;
  warehouse: typeof Warehouse;
}

const combinedReducers = combineReducers({
  Brand,
  Categories,
  Category,
  ComparisonPortal,
  Config,
  Countries,
  Country,
  Currency,
  Dashboard,
  Discount,
  Downloader,
  Ebay,
  FeatureFlags,
  Language,
  Messenger,
  Order,
  Orders,
  Product,
  Products,
  Properties,
  Property,
  Report,
  Search,
  Tags,
  User,
  Warehouse,
});

const rootReducer = withReduxStateSync(combinedReducers);

export type RootState = ReturnType<typeof combinedReducers>;

export default rootReducer;
