import { createReducer } from '@reduxjs/toolkit';

import { featureFlagsLoadAll, featureFlagsLoadAllResponse } from './actions';
import { type IFeatureFlagsState } from './types';

export const preloadedState: IFeatureFlagsState = {
  inProcess: false,
  featureFlags: undefined,
  updatedAt: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(featureFlagsLoadAll, (state) => {
    state.inProcess = true;
    state.featureFlags = undefined;
    state.updatedAt = undefined;
  });

  builder.addCase(featureFlagsLoadAllResponse, (state, action) => {
    state.inProcess = false;
    state.featureFlags = action.payload.featureFlags;
    state.updatedAt = Date.now();
  });
});
