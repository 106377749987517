import {
  type IAdminWarehouseProductDetail,
  type IAdminWarehouseProductList,
  type IProduct,
  type IProductDownloaded,
  type IProductDownloadedList,
  type IWarehouseCategory,
  type IWarehouseProperty,
} from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { DOWNLOADER_ACTION } from './types';

export const downloaderLoadProducts = createAction(
  DOWNLOADER_ACTION.LOAD_PRODUCTS,
  (lastDaysUpdatedAt: number) => ({
    payload: {
      lastDaysUpdatedAt,
    },
  }),
);

export const downloaderLoadProductsRespose = createAction(
  DOWNLOADER_ACTION.LOAD_PRODUCTS_RESPONSE,
  (products: IProductDownloadedList[]) => ({
    payload: {
      products,
    },
  }),
);

export const downloaderLoadProduct = createAction(
  DOWNLOADER_ACTION.LOAD_PRODUCT,
  (productDownloadedUUID: string) => ({
    payload: {
      productDownloaded: {
        uuid: productDownloadedUUID,
      },
    },
  }),
);

export const downloaderLoadProductResponse = createAction(
  DOWNLOADER_ACTION.LOAD_PRODUCT_RESPONSE,
  (product: IProductDownloaded | null) => ({
    payload: {
      product,
    },
  }),
);

export const downloaderLoadWarehouseCategories = createAction(
  DOWNLOADER_ACTION.LOAD_WAREHOUSE_CATEGORIES,
);

export const downloaderLoadWarehouseCategoriesResponse = createAction(
  DOWNLOADER_ACTION.LOAD_WAREHOUSE_CATEGORIES_RESPONSE,
  (categories: IWarehouseCategory[]) => ({
    payload: {
      categories,
    },
  }),
);

export const downloaderLoadWarehouseCategory = createAction(
  DOWNLOADER_ACTION.LOAD_WAREHOUSE_CATEGORY,
  (uuid: string) => ({
    payload: {
      warehouseCategory: {
        uuid,
      },
    },
  }),
);

export const downloaderLoadWarehouseCategoryResponse = createAction(
  DOWNLOADER_ACTION.LOAD_WAREHOUSE_CATEGORY_RESPONSE,
  (category: IWarehouseCategory | null) => ({
    payload: {
      category,
    },
  }),
);

export const downloaderPairWarehouseCategory = createAction(
  DOWNLOADER_ACTION.PAIR_WAREHOUSE_CATEGORY,
  (uuid: string, categoryUUID: string) => ({
    payload: {
      warehouseCategory: {
        uuid,
      },
      category: {
        uuid: categoryUUID,
      },
    },
  }),
);

export const downloaderPairWarehouseCategoryResponse = createAction(
  DOWNLOADER_ACTION.PAIR_WAREHOUSE_CATEGORY_RESPONSE,
  (category: IWarehouseCategory | null) => ({
    payload: {
      category,
    },
  }),
);

export const downloaderLoadWarehouseProducts = createAction(
  DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCTS,
  (
    minimalWeight: number,
    maximalWeight: number,
    minimalPrice: number,
    maximalPrice: number,
  ) => ({
    payload: {
      weight: {
        minimal: minimalWeight,
        maximal: maximalWeight,
      },
      price: {
        minimal: minimalPrice,
        maximal: maximalPrice,
      },
    },
  }),
);

export const downloaderLoadWarehouseProductsResponse = createAction(
  DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCTS_RESPONSE,
  (products: IAdminWarehouseProductList[]) => ({
    payload: {
      products,
    },
  }),
);

export const downloaderLoadWarehouseProduct = createAction(
  DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCT,
  (uuid: string) => ({
    payload: {
      warehouseProduct: {
        uuid,
      },
    },
  }),
);

export const downloaderLoadWarehouseProductResponse = createAction(
  DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCT_RESPONSE,
  (product: IAdminWarehouseProductDetail | null) => ({
    payload: {
      product,
    },
  }),
);

export const downloaderLoadWarehouseProductsByWarehouseCategory = createAction(
  DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCTS_BY_WAREHOUSE_CATEGORY,
  (uuid: string) => ({
    payload: {
      warehouseCategory: {
        uuid,
      },
    },
  }),
);

export const downloaderLoadWarehouseProductsByWarehouseCategoryResponse =
  createAction(
    DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCTS_BY_WAREHOUSE_CATEGORY_RESPONSE,
    (products: IAdminWarehouseProductList[] | null) => ({
      payload: {
        products,
      },
    }),
  );

export const downloaderAddWarehouseProduct = createAction(
  DOWNLOADER_ACTION.ADD_WAREHOUSE_PRODUCT,
  (downloaderWarehouseProductUUID: string, name: string) => ({
    payload: {
      warehouseProduct: {
        uuid: downloaderWarehouseProductUUID,
      },
      name,
    },
  }),
);

export const downloaderAddWarehouseProductResponse = createAction(
  DOWNLOADER_ACTION.ADD_WAREHOUSE_PRODUCT_RESPONSE,
  (product: IProduct | null) => ({
    payload: {
      product,
    },
  }),
);

export const downloaderSyncWarehouseProduct = createAction(
  DOWNLOADER_ACTION.SYNC_WAREHOUSE_PRODUCT,
  (productUUID: string | undefined, warehouseProductUUID: string) => ({
    payload: {
      product: {
        uuid: productUUID,
      },
      warehouseProduct: {
        uuid: warehouseProductUUID,
      },
    },
  }),
);

export const downloaderSyncWarehouseProductResponse = createAction(
  DOWNLOADER_ACTION.SYNC_WAREHOUSE_PRODUCT_RESPONSE,
  (synced: boolean) => ({
    payload: {
      synced,
    },
  }),
);

export const downloaderUnassignWarehouseProduct = createAction(
  DOWNLOADER_ACTION.UNASSIGN_WAREHOUSE_PRODUCT,
  (
    productUUID: string,
    warehouseProductUUID: string,
    warehouseUUID: string,
  ) => ({
    payload: {
      product: {
        uuid: productUUID,
      },
      warehouseProduct: {
        uuid: warehouseProductUUID,
      },
      warehouse: {
        uuid: warehouseUUID,
      },
    },
  }),
);

export const downloaderUnassignWarehouseProductResponse = createAction(
  DOWNLOADER_ACTION.UNASSIGN_WAREHOUSE_PRODUCT_RESPONSE,
  (unassigned: boolean) => ({
    payload: {
      unassigned,
    },
  }),
);

export const downloaderDownloadAllPhotosWarehouseProduct = createAction(
  DOWNLOADER_ACTION.DOWNLOAD_ALL_PHOTOS_WAREHOUSE_PRODUCT,
  (uuid: string) => ({
    payload: {
      warehouseProduct: {
        uuid,
      },
    },
  }),
);

export const downloaderDownloadAllPhotosWarehouseProductResponse = createAction(
  DOWNLOADER_ACTION.DOWNLOAD_ALL_PHOTOS_WAREHOUSE_PRODUCT_RESPONSE,
  (downloaded: boolean) => ({
    payload: {
      downloaded,
    },
  }),
);

export const downloaderWarehouseProductPairWithExistingProduct = createAction(
  DOWNLOADER_ACTION.WAREHOUSE_PRODUCT_PAIR_WITH_EXISTING_PRODUCT,
  (productUUID: string, warehouseProductUUID: string) => ({
    payload: {
      product: {
        uuid: productUUID,
      },
      warehouseProduct: {
        uuid: warehouseProductUUID,
      },
    },
  }),
);

export const downloaderWarehouseProductPairWithExistingProductResponse =
  createAction(
    DOWNLOADER_ACTION.WAREHOUSE_PRODUCT_PAIR_WITH_EXISTING_PRODUCT_RESPONSE,
    (paired: boolean) => ({
      payload: {
        paired,
      },
    }),
  );

export const downloaderLoadWarehouseProperties = createAction(
  DOWNLOADER_ACTION.LOAD_WAREHOUSE_PROPERTIES,
  (assignedToProperty: boolean | undefined = undefined) => ({
    payload: {
      assignedToProperty,
    },
  }),
);

export const downloaderLoadWarehousePropertiesResponse = createAction(
  DOWNLOADER_ACTION.LOAD_WAREHOUSE_PROPERTIES_RESPONSE,
  (properties: IWarehouseProperty[]) => ({
    payload: {
      properties,
    },
  }),
);

export const downloaderLoadWarehouseProperty = createAction(
  DOWNLOADER_ACTION.LOAD_WAREHOUSE_PROPERTY,
  (rawName: string) => ({
    payload: {
      warehouseProperty: {
        rawName,
      },
    },
  }),
);

export const downloaderLoadWarehousePropertyResponse = createAction(
  DOWNLOADER_ACTION.LOAD_WAREHOUSE_PROPERTY_RESPONSE,
  (property: IWarehouseProperty | null) => ({
    payload: {
      property,
    },
  }),
);

export const downloaderDeleteWarehouseProduct = createAction(
  DOWNLOADER_ACTION.DELETE_WAREHOUSE_PRODUCT,
  (uuid: string) => ({
    payload: {
      warehouseProduct: {
        uuid,
      },
    },
  }),
);

export const downloaderDeleteWarehouseProductResponse = createAction(
  DOWNLOADER_ACTION.DELETE_WAREHOUSE_PRODUCT_RESPONSE,
  (deleted: boolean) => ({
    payload: {
      deleted,
    },
  }),
);

export const downloaderHideWarehouseProduct = createAction(
  DOWNLOADER_ACTION.HIDE_WAREHOUSE_PRODUCT,
  (uuid: string, hide: boolean) => ({
    payload: {
      warehouseProduct: {
        uuid,
      },
      hide,
    },
  }),
);

export const downloaderHideWarehouseProductResponse = createAction(
  DOWNLOADER_ACTION.HIDE_WAREHOUSE_PRODUCT_RESPONSE,
  (hidden: boolean) => ({
    payload: {
      hidden,
    },
  }),
);
