import { ReloadOutlined } from '@ant-design/icons';
import { removeDiacritics } from '@mahawi/eshop-common/dist/src/remove-diacritics';
import { type IWarehouseCategory } from '@mahawi/eshop-common/dist/src/types';
import { Button, Divider, Space, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import { type ColumnFilterItem } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Link from '../components/link';
import Table from '../components/table';
import { type RootState } from '../reducers';
import { downloaderLoadWarehouseCategories } from '../reducers/downloader/actions';
import { type IDownloaderState } from '../reducers/downloader/types';
interface DataType extends IWarehouseCategory {
  key: string;
  nameNormalized: string;
}

function DownloaderWarehouseCategories({
  dispatch,
  Downloader,
}: {
  dispatch: Dispatch;
  Downloader: IDownloaderState;
}): JSX.Element {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);

  useEffect((): void => {
    const dataSourceUE: DataType[] = [];
    const categories = new Set<string>([]);
    const filters: Map<string, ColumnFilterItem> = new Map();

    Downloader.warehouse.categories?.forEach(
      (category: IWarehouseCategory): void => {
        const categoryNameSplitted: string[] = category.name.split(' > ');
        categoryNameSplitted.forEach((categoryName: string): void => {
          categories.add(categoryName);
        });

        const nameNormalized: string = removeDiacritics(
          category.name.toLowerCase().trim(),
        );

        dataSourceUE.push({
          key: category.uuid,
          ...category,
          nameNormalized,
        });

        const filterItem: ColumnFilterItem = {
          text: category.name,
          value: nameNormalized,
        };

        filters.set(nameNormalized, filterItem);
      },
    );

    setDataSource(dataSourceUE);

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: DataType, b: DataType): number =>
          a.rawName.localeCompare(b.rawName),
        filters: [...filters.values()].sort(
          (a: ColumnFilterItem, b: ColumnFilterItem): number =>
            String(a.text).localeCompare(String(b.text)),
        ),
        filterSearch: (
          value: string,
          columFilterItem: ColumnFilterItem,
        ): boolean => {
          const valueNormalized: string = removeDiacritics(
            value.toLowerCase().trim(),
          );
          return String(columFilterItem.value).includes(valueNormalized);
        },
        onFilter: (valueNormalized: string, record: DataType): boolean =>
          record.nameNormalized === valueNormalized,
        render: (_text, record: DataType): JSX.Element => (
          <Link
            to={`/downloader/warehouse-category/${record.uuid}`}
            label={record.name}
            openInNewTab
          />
        ),
        width: '90%',
      },
      {
        title: 'Actions',
        sorter: (a: DataType, b: DataType): number =>
          (b.category?.uuid || '').localeCompare(a.category?.uuid || '') || 0,
        render: (_text, record: DataType): JSX.Element => (
          <Space size={16} align="start">
            {record.category?.uuid ? (
              <Link to={`/category/${record.category.uuid}`} openInNewTab />
            ) : null}
          </Space>
        ),
        width: '10%',
      },
    ];

    setColumns(columnsUE);
  }, [Downloader.warehouse.categories]);

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      <Space size={16} align="center" split={<Divider type="vertical" />}>
        <Button
          type={
            !Downloader.warehouse.categories?.length ? 'primary' : 'default'
          }
          onClick={(): void => {
            dispatch(downloaderLoadWarehouseCategories());
          }}
          disabled={Downloader.inProcess}
        >
          Reload all <ReloadOutlined />
        </Button>

        <Typography>
          {Downloader.updatedAt &&
            `Updated at ${dayjs(Downloader.updatedAt).fromNow()}`}
        </Typography>
      </Space>

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Downloader.inProcess}
      />
    </Space>
  );
}

const mapStateToProps = ({
  Downloader,
}: RootState): { Downloader: IDownloaderState } => ({ Downloader });

export default connect(mapStateToProps)(DownloaderWarehouseCategories);
