import { ReloadOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Switch, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import LanguageButtons from '../components/language-buttons';
import ProductAdd from '../components/product/product-add';
import Table from '../components/table';
import {
  getDataSourceForProductsTable,
  getProductsTableColumns,
  type IProductsTableColumnsProps,
} from '../fragments/products-table-columns';
import { type RootState } from '../reducers';
import { type IConfigState } from '../reducers/config/types';
import { type ICurrencyState } from '../reducers/currency/types';
import { type IFeatureFlagsState } from '../reducers/feature-flags/types';
import { type ILanguageState } from '../reducers/language/types';
import { productsLoadAll } from '../reducers/products/actions';
import { type IProductsState } from '../reducers/products/types';

function ProductsContainer({
  dispatch,
  Config,
  Products,
  Language,
  Currency,
  FeatureFlags,
}: {
  dispatch: Dispatch;
  Config: IConfigState;
  Products: IProductsState;
  Language: ILanguageState;
  Currency: ICurrencyState;
  FeatureFlags: IFeatureFlagsState;
}): JSX.Element {
  const [dataSource, setDataSource] = useState<IProductsTableColumnsProps[]>(
    [],
  );
  const [columns, setColumns] = useState<ColumnsType<object>>([]);
  const [barElement, setBarElement] = useState<JSX.Element | undefined>();
  const [isActiveProducts, setIsActiveProducts] = useState<boolean>(true);

  useEffect((): void => {
    const dataSourceUE: IProductsTableColumnsProps[] =
      getDataSourceForProductsTable({
        Products,
        Language,
      });

    const columnsUE: ColumnsType<object> = getProductsTableColumns({
      dataSource: dataSourceUE,
      Config,
      Currency,
      Language,
      FeatureFlags,
    });

    setDataSource(dataSourceUE);
    setColumns(columnsUE);
  }, [Config, Language, Products, Products.updatedAt]);

  useEffect((): void => {
    const barElementUE: JSX.Element = (
      <Space size={16} align="center" split={<Divider type="vertical" />}>
        <Switch
          checkedChildren="Active products"
          unCheckedChildren="Disabled products"
          checked={isActiveProducts}
          onChange={(checked: boolean): void => {
            setIsActiveProducts(checked);
          }}
        />

        <Button
          type={!Products.products ? 'primary' : 'default'}
          onClick={(): void => {
            dispatch(productsLoadAll(isActiveProducts));
          }}
          disabled={Products.inProcess}
        >
          Reload all <ReloadOutlined />
        </Button>

        <Typography>
          {Products.updatedAt &&
            `Updated at ${dayjs(Products.updatedAt).format(
              'YYYY-MM-DD HH:mm:ss',
            )}`}
        </Typography>
      </Space>
    );

    setBarElement(barElementUE);
  }, [
    Products.inProcess,
    Products.updatedAt,
    Products.products,
    dispatch,
    isActiveProducts,
  ]);

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      {barElement}

      <LanguageButtons />

      <ProductAdd />

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Products.inProcess && !Products.products}
      />
    </Space>
  );
}

const mapStateToProps = ({
  Products,
  Language,
  Config,
  Currency,
  FeatureFlags,
}: RootState) => ({
  Products,
  Language,
  Config,
  Currency,
  FeatureFlags,
});

export default connect(mapStateToProps)(ProductsContainer);
