import { type RootState } from 'admin/react/reducers';
import { Collapse } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import {
  getDataSourceForProductsTable,
  getProductsTableColumns,
  type IProductsTableColumnsProps,
} from '../../fragments/products-table-columns';
import { type ICategoryState } from '../../reducers/category/types';
import { type IConfigState } from '../../reducers/config/types';
import { type ICurrencyState } from '../../reducers/currency/types';
import { type IFeatureFlagsState } from '../../reducers/feature-flags/types';
import { type ILanguageState } from '../../reducers/language/types';
import { productsLoadByCategory } from '../../reducers/products/actions';
import { type IProductsState } from '../../reducers/products/types';
import Table from '../table';

function ProductsContainer({
  dispatch,
  Config,
  Products,
  Language,
  Category,
  Currency,
  FeatureFlags,
}: {
  dispatch: Dispatch;
  Config: IConfigState;
  Products: IProductsState;
  Language: ILanguageState;
  Category: ICategoryState;
  Currency: ICurrencyState;
  FeatureFlags: IFeatureFlagsState;
}): JSX.Element {
  const [dataSource, setDataSource] = useState<IProductsTableColumnsProps[]>(
    [],
  );
  const [columns, setColumns] = useState<ColumnsType<object>>([]);

  useEffect((): void => {
    const dataSourceUE: IProductsTableColumnsProps[] =
      getDataSourceForProductsTable({
        Products,
        Language,
      });

    const columnsUE: ColumnsType<object> = getProductsTableColumns({
      dataSource: dataSourceUE,
      Config,
      Language,
      Currency,
      FeatureFlags,
    });

    setDataSource(dataSourceUE);
    setColumns(columnsUE);
  }, [Config, Language, Products, Products.inProcess]);

  return (
    <Collapse
      onChange={(keys: string[]): void => {
        if (keys.includes('products') && Category.category?.uuid) {
          dispatch(productsLoadByCategory(Category.category.uuid));
        }
      }}
    >
      <Collapse.Panel header="Products" key="products">
        <Table
          columns={columns}
          dataSource={dataSource}
          isLoading={!Products.products}
        />
      </Collapse.Panel>
    </Collapse>
  );
}

const mapStateToProps = ({
  Products,
  Language,
  Config,
  Category,
  Currency,
  FeatureFlags,
}: RootState): {
  Products: IProductsState;
  Language: ILanguageState;
  Config: IConfigState;
  Category: ICategoryState;
  Currency: ICurrencyState;
  FeatureFlags: IFeatureFlagsState;
} => ({
  Products,
  Language,
  Config,
  Category,
  Currency,
  FeatureFlags,
});

export default connect(mapStateToProps)(ProductsContainer);
