import { blue, green } from '@ant-design/colors';
import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { type SizeType } from 'antd/es/config-provider/SizeContext';
import React, { useState } from 'react';

function CopyToClipboard({
  text,
  title = 'Copy to clipboard!',
  children = null,
  size = 'large',
  type = 'default',
}: {
  text: string;
  title?: string;
  children?: React.ReactNode;
  size?: SizeType;
  type?: 'primary' | 'dashed' | 'link' | 'text' | 'default';
}): JSX.Element {
  const [copied, setCopied] = useState(false);

  const childrenToShow:
    | string
    | number
    | true
    | Iterable<React.ReactNode>
    | React.JSX.Element = children || (
    <CopyOutlined style={{ color: blue.primary }} />
  );

  if (!text) {
    return <CopyOutlined disabled />;
  }

  return (
    <Tooltip title={copied ? 'Copied!' : title}>
      <Button
        type={type}
        size={size}
        onClick={async (): Promise<void> => {
          await navigator.clipboard.writeText(text.trim());
          setCopied(true);
          setTimeout((): void => setCopied(false), 3000);
        }}
      >
        {copied && !children ? (
          <CheckCircleOutlined style={{ color: green.primary }} />
        ) : (
          childrenToShow
        )}
      </Button>
    </Tooltip>
  );
}

export default CopyToClipboard;
