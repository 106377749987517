import {
  DeleteOutlined,
  DownloadOutlined,
  EyeInvisibleOutlined,
  GlobalOutlined,
  ReloadOutlined,
  SaveOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { currencyFormat } from '@mahawi/eshop-common/dist/src/currency-format';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import { round } from '@mahawi/eshop-common/dist/src/round';
import { stringProbableMatch } from '@mahawi/eshop-common/dist/src/string-probable-match';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import {
  EAdminWarehouseProductType,
  EFeatureFlag,
  type IAdminWarehouseProductDetail,
  type IProduct,
  type IWarehouseCategory,
  type IWarehouseDetail,
  type IWarehouseProductFeatureFlag,
} from '@mahawi/eshop-common/dist/src/types';
import {
  Alert,
  Button,
  Col,
  Descriptions,
  Divider,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import Markdown from 'markdown-to-jsx';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { type Dispatch } from 'redux';

import CopyToClipboard from '../components/copy-to-clipboard';
import WarehouseProductAdd from '../components/downloader/warehouse-product-add';
import Link from '../components/link';
import { featureFlagTags } from '../fragments/feature-flag-tags';
import { type RootState } from '../reducers';
import {
  downloaderDeleteWarehouseProduct,
  downloaderDownloadAllPhotosWarehouseProduct,
  downloaderHideWarehouseProduct,
  downloaderLoadWarehouseProduct,
  downloaderSyncWarehouseProduct,
  downloaderWarehouseProductPairWithExistingProduct,
} from '../reducers/downloader/actions';
import { type IDownloaderState } from '../reducers/downloader/types';
import { type IFeatureFlagsState } from '../reducers/feature-flags/types';
import { type ILanguageState } from '../reducers/language/types';
import {
  productsGetByGTIN,
  productsGetByMPN,
} from '../reducers/products/actions';
import { type IProductsState } from '../reducers/products/types';
import { type IWarehouseState } from '../reducers/warehouse/types';

const categoriesHeaderEl: JSX.Element = (
  <Row gutter={16} key="header">
    <Col span={10}>
      <Typography.Paragraph strong>
        Warehosue category name
      </Typography.Paragraph>
    </Col>

    <Col span={2}>
      <Typography.Paragraph strong>Actions</Typography.Paragraph>
    </Col>

    <Col span={10}>
      <Typography.Paragraph strong>Category name</Typography.Paragraph>
    </Col>

    <Col span={2}>
      <Typography.Paragraph strong>Actions</Typography.Paragraph>
    </Col>
  </Row>
);

function DownloaderWarehouseProduct({
  dispatch,
  Downloader,
  Language,
  Products,
  FeatureFlags,
  Warehouse,
}: {
  dispatch: Dispatch;
  Downloader: IDownloaderState;
  Language: ILanguageState;
  Products: IProductsState;
  FeatureFlags: IFeatureFlagsState;
  Warehouse: IWarehouseState;
}): JSX.Element {
  const { uuid } = useParams();
  const [product, setProduct] = useState<IAdminWarehouseProductDetail | null>(
    null,
  );
  const [categoriesEl, setCategoriesEl] = useState<JSX.Element[]>([]);
  const [nameNormalized, setNameNormalized] = useState<string>('');
  const [productsSimilar, setProductsSimilar] = useState<JSX.Element[]>([]);
  const [featureFlagEls, setFeatureFlagEls] = useState<JSX.Element[]>([]);
  const [warehouse, setWarehouse] = useState<IWarehouseDetail | undefined>();

  const reload = useCallback(
    (uuid: string | undefined): void => {
      if (uuid) {
        dispatch(downloaderLoadWarehouseProduct(uuid));
      }
    },
    [dispatch],
  );

  useEffect((): void => {
    reload(uuid);
  }, [uuid]);

  useEffect((): void => {
    if (!Downloader.warehouse.product) {
      return;
    }

    const nameNormalizedUE: string = Downloader.warehouse.product.name
      .replaceAll('/', ' ')
      .replaceAll(',', ' ')
      .replaceAll(/\s{2,}/g, ' ')
      .trim();

    setNameNormalized(nameNormalizedUE);
  }, [Downloader.warehouse.product]);

  useEffect((): void => {
    switch (Downloader.warehouse.product?.type) {
      case EAdminWarehouseProductType.DETAIL:
        setProduct(Downloader.warehouse.product);
        break;
      default:
        setProduct(null);
    }
  }, [Downloader.warehouse.product]);

  useEffect((): void => {
    const categoriesUE: JSX.Element[] =
      product?.categories
        .map((category: IWarehouseCategory): JSX.Element => {
          const categoryName: string = getTranslation(
            category.category?.names,
            Language.languageType,
          );

          return (
            <Row key={category.uuid} gutter={16}>
              <Col span={10}>
                <Typography.Paragraph>{category.name}</Typography.Paragraph>
              </Col>

              <Col span={2}>
                <Space size={8} align="baseline">
                  <CopyToClipboard text={category.name} type="link" />

                  <Link
                    to={`/downloader/warehouse-category/${category.uuid}`}
                    openInNewTab
                  />
                </Space>
              </Col>

              <Col span={10}>
                {category.category && (
                  <Typography.Paragraph>{categoryName}</Typography.Paragraph>
                )}
              </Col>

              <Col span={2}>
                <Space size={8} align="baseline">
                  {category.category?.uuid && (
                    <Link
                      to={`/category/${category.category.uuid}`}
                      openInNewTab
                    />
                  )}
                </Space>
              </Col>
            </Row>
          );
        })
        .filter(notEmpty) || [];

    setCategoriesEl([...[categoriesHeaderEl], ...categoriesUE]);
  }, [product, Language.languageType?.code]);

  useEffect((): void => {
    if (!Products.products) {
      setProductsSimilar([]);
      return;
    }

    const elements: JSX.Element[] = Products.products.map(
      ({ names, uuid: productUUID, gtin }: IProduct): JSX.Element => {
        const productName: string = getTranslation(
          names,
          Language.languageType,
        );

        return (
          <Row key={productUUID} gutter={16}>
            <Col span={10}>
              <Typography.Paragraph>{productName}</Typography.Paragraph>
            </Col>

            <Col span={2}>
              <Typography.Paragraph>{gtin || '---'}</Typography.Paragraph>
            </Col>

            <Col span={2}>
              <Space size={8} style={{ width: '100%' }}>
                <Link to={`/product/${productUUID}`} openInNewTab />
                <Popconfirm
                  title="Are you sure to pair with this product?"
                  description="Warehouse product will be paired with this product."
                  onConfirm={(): void => {
                    if (Downloader.warehouse.product?.uuid) {
                      dispatch(
                        downloaderWarehouseProductPairWithExistingProduct(
                          productUUID,
                          Downloader.warehouse.product.uuid,
                        ),
                      );
                      dispatch(
                        downloaderSyncWarehouseProduct(
                          productUUID,
                          Downloader.warehouse.product.uuid,
                        ),
                      );
                    }
                  }}
                >
                  <SaveOutlined />
                </Popconfirm>
              </Space>
            </Col>

            <Col span={2}>
              <Typography.Paragraph>
                {round(
                  stringProbableMatch({
                    first: productName,
                    second: nameNormalized,
                  }) * 100,
                  0,
                )}{' '}
                %
              </Typography.Paragraph>
            </Col>
          </Row>
        );
      },
    );

    setProductsSimilar(elements);
  }, [
    Products.products,
    Language.languageType?.code,
    Downloader.warehouse.product?.uuid,
    dispatch,
  ]);

  useEffect((): void => {
    if (!Downloader.warehouse.product) {
      return;
    }

    const featureFlagElsUE: JSX.Element[] = featureFlagTags(
      Downloader.warehouse.product?.featureFlags,
      FeatureFlags,
    );

    setFeatureFlagEls(featureFlagElsUE);
  }, [FeatureFlags.updatedAt, FeatureFlags.featureFlags]);

  useEffect((): void => {
    if (!Downloader.warehouse.product?.warehouse) {
      return;
    }

    const warehouse: IWarehouseDetail | undefined = Warehouse.warehouses?.find(
      (warehouse: IWarehouseDetail): boolean =>
        warehouse.uuid === Downloader.warehouse.product?.warehouse.uuid,
    );

    setWarehouse(warehouse);
  }, [
    Downloader.warehouse.product?.warehouse,
    Warehouse.warehouses,
    Warehouse.updatedAt,
  ]);

  if (Downloader.warehouse.product === undefined || !uuid) {
    return <Skeleton active />;
  }

  if (Downloader.warehouse.product === null) {
    return (
      <Alert
        message="Warehouse product not found"
        description={`Warehouse product with uuid ${uuid} not found.`}
        type="error"
        showIcon
      />
    );
  }

  return (
    <Space size={8} direction="vertical" style={{ width: '100%' }}>
      <Typography.Title level={2}>Warehouse product</Typography.Title>

      <Space size={8} align="center">
        <Tooltip title="Reload all information from database">
          <Button
            color="primary"
            onClick={(): void => {
              reload(uuid);
            }}
            disabled={Downloader.inProcess}
          >
            Reload <ReloadOutlined />
          </Button>
        </Tooltip>

        <Tooltip title="Sync product with warehouse">
          <Button
            color="primary"
            onClick={(): void => {
              if (uuid) {
                dispatch(downloaderSyncWarehouseProduct(undefined, uuid));
              }
            }}
            disabled={Downloader.inProcess}
          >
            Sync <ReloadOutlined />
          </Button>
        </Tooltip>

        <Tooltip title="Download all photos">
          <Button
            color="primary"
            onClick={(): void => {
              if (uuid) {
                dispatch(downloaderDownloadAllPhotosWarehouseProduct(uuid));
              }
            }}
            disabled={Downloader.inProcess}
          >
            Download all photos <DownloadOutlined />
          </Button>
        </Tooltip>

        <Popconfirm
          title="Are you sure to hide this warehouse product?"
          description="Warehouse product will be hidden in warehouse product lists."
          okText="Yes"
          cancelText="No"
          onConfirm={(): void => {
            if (Downloader.warehouse.product?.uuid) {
              dispatch(
                downloaderHideWarehouseProduct(
                  Downloader.warehouse.product.uuid,
                  true,
                ),
              );
            }
          }}
        >
          <Button
            color="default"
            variant="outlined"
            disabled={
              Downloader.inProcess ||
              Downloader.warehouse.product.featureFlags?.filter(
                (flag: IWarehouseProductFeatureFlag): boolean =>
                  flag.featureFlag === EFeatureFlag.WAREHOUSE_PRODUCT_HIDDEN,
              ).length > 0
            }
          >
            Hide <EyeInvisibleOutlined />
          </Button>
        </Popconfirm>

        <Popconfirm
          title="Are you sure to delete this product?"
          description="Warehouse product will be deleted, action cannot be undone."
          okText="Yes"
          cancelText="No"
          onConfirm={(): void => {
            if (Downloader.warehouse.product?.uuid) {
              dispatch(
                downloaderDeleteWarehouseProduct(
                  Downloader.warehouse.product.uuid,
                ),
              );
            }
          }}
        >
          <Button
            color="danger"
            variant="outlined"
            disabled={Downloader.inProcess}
          >
            Delete <DeleteOutlined />
          </Button>
        </Popconfirm>

        {featureFlagEls}
      </Space>

      <Descriptions
        bordered
        column={2}
        size="small"
        contentStyle={{ width: '50%' }}
      >
        <Descriptions.Item label="UUID">
          <Space size={8} align="baseline">
            {Downloader.warehouse.product.uuid}

            <CopyToClipboard
              text={Downloader.warehouse.product.uuid}
              type="link"
            />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Warehouse name">
          <Space size={8} align="baseline">
            {Downloader.warehouse.product.warehouse.name}

            <CopyToClipboard
              text={Downloader.warehouse.product.warehouse.name}
              type="link"
            />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Name">
          <Space size={8} align="baseline">
            {Downloader.warehouse.product.name}

            <CopyToClipboard
              text={Downloader.warehouse.product.name}
              type="link"
            />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Warehouse product code">
          <Space size={8} align="baseline">
            {Downloader.warehouse.product.productCode}

            <CopyToClipboard
              text={Downloader.warehouse.product.productCode}
              type="link"
            />

            {Downloader.warehouse.product.url ? (
              <a
                href={Downloader.warehouse.product.url}
                target="_blank"
                rel="noreferrer noopener nofollow"
                aria-label="Open external source URL in new tab"
              >
                <GlobalOutlined />
              </a>
            ) : (
              <GlobalOutlined disabled />
            )}
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Name (normalized)">
          <Space size={8} align="baseline">
            {nameNormalized}

            <CopyToClipboard text={nameNormalized} type="link" />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Brand">
          {Downloader.warehouse.product.brand}
        </Descriptions.Item>

        <Descriptions.Item label="Prices">
          <Space size={8} align="start" direction="vertical">
            <Typography.Paragraph>
              Purchase:{' '}
              <Typography.Text strong>
                {currencyFormat(
                  Downloader.warehouse.product.price,
                  warehouse?.currencyType,
                )}
              </Typography.Text>
            </Typography.Paragraph>
            <Typography.Paragraph>
              Recommended:{' '}
              <Typography.Text strong>
                {currencyFormat(
                  Downloader.warehouse.product.recommendedPrice,
                  warehouse?.currencyType,
                )}
              </Typography.Text>
            </Typography.Paragraph>
            <Typography.Paragraph>
              Margin:{' '}
              <Typography.Text strong>
                {currencyFormat(
                  Downloader.warehouse.product.recommendedPrice -
                    Downloader.warehouse.product.price,
                  warehouse?.currencyType,
                )}
              </Typography.Text>
            </Typography.Paragraph>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Identification">
          <Space size={8} direction="vertical" style={{ width: '100%' }}>
            <Space size={8} align="baseline">
              <Typography.Paragraph>
                MPN:{' '}
                <Typography.Text strong>
                  {Downloader.warehouse.product.mpn}
                </Typography.Text>
              </Typography.Paragraph>

              <CopyToClipboard
                text={Downloader.warehouse.product.mpn}
                type="link"
              />

              <SearchOutlined
                onClick={(): void => {
                  if (Downloader.warehouse.product?.mpn) {
                    dispatch(
                      productsGetByMPN(Downloader.warehouse.product.mpn),
                    );
                  }
                }}
                disabled={!Downloader.warehouse.product?.mpn}
              />
            </Space>

            <Space size={8} align="baseline">
              <Typography.Paragraph>
                GTIN:{' '}
                <Typography.Text strong>
                  {Downloader.warehouse.product.gtin}
                </Typography.Text>
              </Typography.Paragraph>

              <CopyToClipboard
                text={Downloader.warehouse.product.gtin}
                type="link"
              />

              <SearchOutlined
                onClick={(): void => {
                  if (Downloader.warehouse.product?.gtin) {
                    dispatch(
                      productsGetByGTIN(Downloader.warehouse.product.gtin),
                    );
                  }
                }}
                disabled={!Downloader.warehouse.product?.gtin}
              />
            </Space>

            {productsSimilar}
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Weight" span={2}>
          <Space size={8} align="baseline">
            {Downloader.warehouse.product.dimensions.weight} Kg
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Categories" span={2}>
          {categoriesEl.map((el: JSX.Element): JSX.Element => el)}
        </Descriptions.Item>

        <Descriptions.Item label="Description" span={2}>
          <Markdown>{product?.description || '---'}</Markdown>
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      {!Downloader.newProduct &&
        !Downloader.warehouse.product.product.uuid &&
        nameNormalized && <WarehouseProductAdd name={nameNormalized} />}

      {Downloader.newProduct && !Downloader.warehouse.product.product.uuid && (
        <Row>
          <Col span={14} offset={5}>
            <Link
              to={`/product/${Downloader.newProduct.uuid}`}
              openInNewTab
              label={`Created new product with UUID ${Downloader.newProduct.uuid}`}
              additionalSearchParams={{
                productCategories: 'open',
                productLanguage: 'open',
              }}
            />
          </Col>
        </Row>
      )}

      {!Downloader.newProduct && Downloader.warehouse.product.product.uuid && (
        <Row>
          <Col span={14} offset={5}>
            <Link
              to={`/product/${Downloader.warehouse.product.product.uuid}`}
              openInNewTab
              label={`Existing product with UUID ${Downloader.warehouse.product.product.uuid}`}
              additionalSearchParams={{
                productCategories: 'open',
                productLanguage: 'open',
              }}
            />
          </Col>
        </Row>
      )}
    </Space>
  );
}

const mapStateToProps = ({
  Downloader,
  Language,
  Products,
  FeatureFlags,
  Warehouse,
}: RootState) => ({
  Downloader,
  Language,
  Products,
  FeatureFlags,
  Warehouse,
});

export default connect(mapStateToProps)(DownloaderWarehouseProduct);
