import { createReducer } from '@reduxjs/toolkit';

import {
  reportProductLanguagesLoad,
  reportProductLanguagesLoadResponse,
  reportPropertyLanguagesLoad,
  reportPropertyLanguagesLoadResponse,
  reportPropertyValueLanguagesLoad,
  reportPropertyValueLanguagesLoadResponse,
  reportWarehouseProductsPairingLoad,
  reportWarehouseProductsPairingLoadResponse,
  reportWarehouseProductToImportLoad,
  reportWarehouseProductToImportLoadResponse,
  reportWarehouseProductTrendLoad,
  reportWarehouseProductTrendLoadResponse,
} from './actions';
import { type IReportState } from './types';

export const preloadedState: IReportState = {
  inProcess: false,
  productLanguages: [],
  propertyLanguages: [],
  propertyValueLanguages: [],
  warehouseProductToImport: undefined,
  warehouseProductTrend: undefined,
  warehouseProductsPairing: undefined,
  updatedAt: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(reportProductLanguagesLoad, (state) => {
    state.inProcess = true;
    state.productLanguages = undefined;
  });

  builder.addCase(reportProductLanguagesLoadResponse, (state, action) => {
    state.inProcess = false;
    state.productLanguages = action.payload.report;
    state.updatedAt = Date.now();
  });

  builder.addCase(reportPropertyLanguagesLoad, (state) => {
    state.inProcess = true;
    state.propertyLanguages = undefined;
  });

  builder.addCase(reportPropertyLanguagesLoadResponse, (state, action) => {
    state.inProcess = false;
    state.propertyLanguages = action.payload.report;
    state.updatedAt = Date.now();
  });

  builder.addCase(reportPropertyValueLanguagesLoad, (state) => {
    state.inProcess = true;
    state.propertyValueLanguages = undefined;
  });

  builder.addCase(reportPropertyValueLanguagesLoadResponse, (state, action) => {
    state.inProcess = false;
    state.propertyValueLanguages = action.payload.report;
    state.updatedAt = Date.now();
  });

  builder.addCase(reportWarehouseProductTrendLoad, (state) => {
    state.inProcess = true;
    state.warehouseProductTrend = undefined;
  });

  builder.addCase(reportWarehouseProductTrendLoadResponse, (state, action) => {
    state.inProcess = false;
    state.warehouseProductTrend = action.payload.report;
    state.updatedAt = Date.now();
  });

  builder.addCase(reportWarehouseProductToImportLoad, (state) => {
    state.inProcess = true;
    state.warehouseProductToImport = undefined;
  });

  builder.addCase(
    reportWarehouseProductToImportLoadResponse,
    (state, action) => {
      state.inProcess = false;
      state.warehouseProductToImport = action.payload.report;
      state.updatedAt = Date.now();
    },
  );

  builder.addCase(reportWarehouseProductsPairingLoad, (state) => {
    state.inProcess = true;
    state.warehouseProductsPairing = undefined;
  });

  builder.addCase(
    reportWarehouseProductsPairingLoadResponse,
    (state, action) => {
      state.inProcess = false;
      state.warehouseProductsPairing = action.payload.report;
      state.updatedAt = Date.now();
    },
  );
});
