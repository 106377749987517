import { GlobalOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import React from 'react';

import CopyToClipboard from '../components/copy-to-clipboard';

export function renderProductCode(
  productCode: string,
  warehouseProductUrl?: string,
): JSX.Element {
  return (
    <Space size={0} align="center">
      <Typography.Text strong>{productCode}</Typography.Text>
      <CopyToClipboard text={productCode} type="link" />
      {warehouseProductUrl ? (
        <a
          href={warehouseProductUrl}
          target="_blank"
          rel="noreferrer noopener nofollow"
          aria-label="Open external source URL in new tab"
        >
          <GlobalOutlined />
        </a>
      ) : (
        <GlobalOutlined disabled />
      )}
    </Space>
  );
}
