import { type IFeatureFlag } from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { FEATURE_FLAGS_ACTION } from './types';

export const featureFlagsLoadAll = createAction(FEATURE_FLAGS_ACTION.LOAD_ALL);

export const featureFlagsLoadAllResponse = createAction(
  FEATURE_FLAGS_ACTION.LOAD_ALL_RESPONSE,
  (featureFlags: IFeatureFlag[]) => ({
    payload: { featureFlags },
  }),
);
