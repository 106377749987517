import { EditOutlined, ExportOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link as LinkRouterDom, useSearchParams } from 'react-router-dom';

import { type RootState } from '../reducers';
import { type ILanguageState } from '../reducers/language/types';

function Link({
  to,
  label,
  openInNewTab,
  Language,
  disabled = false,
  additionalSearchParams = {},
}: {
  to: string;
  label?: string | JSX.Element;
  openInNewTab?: boolean;
  Language: ILanguageState;
  disabled?: boolean;
  additionalSearchParams?: Record<string, string>;
}): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect((): void => {
    if (!Language.languageType?.code) {
      return;
    }

    searchParams.set('languageTypeCode', Language.languageType.code);

    setSearchParams(searchParams);
  }, [Language.languageType?.code]);

  useEffect((): void => {
    if (
      !additionalSearchParams ||
      !Object.keys(additionalSearchParams).length ||
      !Object.values(additionalSearchParams).join()
    ) {
      return;
    }

    for (const key in additionalSearchParams) {
      if (Object.prototype.hasOwnProperty.call(additionalSearchParams, key)) {
        searchParams.set(key, additionalSearchParams[key]);
      }
    }

    setSearchParams(searchParams);
  }, [
    additionalSearchParams,
    Object.keys(additionalSearchParams).length,
    Object.values(additionalSearchParams).join(),
  ]);

  let renderedLabel: string | JSX.Element | undefined = label;

  if (openInNewTab && label) {
    renderedLabel = (
      <Space size={8} align="start">
        {label}
        <ExportOutlined />
      </Space>
    );
  } else if (openInNewTab && !label) {
    renderedLabel = <ExportOutlined />;
  } else if (!openInNewTab && label) {
    renderedLabel = label;
  } else if (!openInNewTab && !label) {
    renderedLabel = <EditOutlined />;
  }

  if (disabled) {
    if (openInNewTab) {
      return <ExportOutlined disabled />;
    }

    return <EditOutlined disabled />;
  }

  return (
    <LinkRouterDom
      to={{ pathname: to, search: searchParams.toString() }}
      target={openInNewTab ? '_blank' : '_self'}
      rel={openInNewTab ? 'noopener noreferrer nofollow' : undefined}
    >
      {renderedLabel}
    </LinkRouterDom>
  );
}

const mapStateToProps = (state: RootState) => ({
  Language: state.Language,
});
export default connect(mapStateToProps)(Link);
