import { stringToColor } from '@mahawi/eshop-common/dist/src/string-to-color';
import {
  EFeatureFlag,
  type IFeatureFlag,
  type IProductFeatureFlag,
} from '@mahawi/eshop-common/dist/src/types';
import { Tag } from 'antd';
import React from 'react';

import { type IFeatureFlagsState } from '../reducers/feature-flags/types';

export const featureFlagTags = (
  productFeatureFlags: IProductFeatureFlag[],
  FeatureFlags: IFeatureFlagsState,
): JSX.Element[] => {
  const featureFlagElements: JSX.Element[] = productFeatureFlags.map(
    (featureFlag: IProductFeatureFlag): JSX.Element => {
      const code: EFeatureFlag = featureFlag.featureFlag;
      const ff: IFeatureFlag | undefined = FeatureFlags.featureFlags?.find(
        (f: IFeatureFlag): boolean => f.code === code,
      );

      if (!ff) {
        return <Tag key={code}>{code}</Tag>;
      }

      const ffColor: string = ff.color || stringToColor(ff.name);

      return (
        <Tag key={code} color={ffColor}>
          {ff.name}
        </Tag>
      );
    },
  );

  return featureFlagElements;
};
