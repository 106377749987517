import {
  type IReportProductLanguage,
  type IReportPropertyLanguage,
  type IReportPropertyValueLanguage,
  type IReportWarehouseProductsPairing,
  type IReportWarehouseProductToImport,
  type TReportWarehouseProductTrend,
} from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { REPORT_ACTION } from './types';

export const reportProductLanguagesLoad = createAction(
  REPORT_ACTION.LOAD_PRODUCT_LANGUAGES,
);

export const reportProductLanguagesLoadResponse = createAction(
  REPORT_ACTION.LOAD_PRODUCT_LANGUAGES_RESPONSE,
  (report: IReportProductLanguage[]) => ({
    payload: {
      report,
    },
  }),
);

export const reportPropertyLanguagesLoad = createAction(
  REPORT_ACTION.LOAD_PROPERTY_LANGUAGES,
);

export const reportPropertyLanguagesLoadResponse = createAction(
  REPORT_ACTION.LOAD_PROPERTY_LANGUAGES_RESPONSE,
  (report: IReportPropertyLanguage[]) => ({
    payload: {
      report,
    },
  }),
);

export const reportPropertyValueLanguagesLoad = createAction(
  REPORT_ACTION.LOAD_PROPERTY_VALUE_LANGUAGES,
);

export const reportPropertyValueLanguagesLoadResponse = createAction(
  REPORT_ACTION.LOAD_PROPERTY_VALUE_LANGUAGES_RESPONSE,
  (report: IReportPropertyValueLanguage[]) => ({
    payload: {
      report,
    },
  }),
);

export const reportWarehouseProductTrendLoad = createAction(
  REPORT_ACTION.LOAD_WAREHOUSE_PRODUCT_TREND,
  (
    dayInterval: number,
    warehouseProductsCount: number,
    excludeOfferedProducts: boolean,
  ) => ({
    payload: {
      dayInterval,
      warehouseProductsCount,
      excludeOfferedProducts,
    },
  }),
);

export const reportWarehouseProductTrendLoadResponse = createAction(
  REPORT_ACTION.LOAD_WAREHOUSE_PRODUCT_TREND_RESPONSE,
  (report: TReportWarehouseProductTrend) => ({
    payload: {
      report,
    },
  }),
);

export const reportWarehouseProductToImportLoad = createAction(
  REPORT_ACTION.LOAD_WAREHOUSE_PRODUCT_TO_IMPORT,
  (
    minimalWeight: number,
    maximalWeight: number,
    minimalPrice: number,
    maximalPrice: number,
  ) => ({
    payload: {
      weight: {
        minimal: minimalWeight,
        maximal: maximalWeight,
      },
      price: {
        minimal: minimalPrice,
        maximal: maximalPrice,
      },
    },
  }),
);

export const reportWarehouseProductToImportLoadResponse = createAction(
  REPORT_ACTION.LOAD_WAREHOUSE_PRODUCT_TO_IMPORT_RESPONSE,
  (report: IReportWarehouseProductToImport[]) => ({
    payload: {
      report,
    },
  }),
);

export const reportWarehouseProductsPairingLoad = createAction(
  REPORT_ACTION.LOAD_WAREHOUSE_PRODUCTS_PAIRING,
  (warehouseUuid: string) => ({
    payload: {
      warehouse: {
        uuid: warehouseUuid,
      },
    },
  }),
);

export const reportWarehouseProductsPairingLoadResponse = createAction(
  REPORT_ACTION.LOAD_WAREHOUSE_PRODUCTS_PAIRING_RESPONSE,
  (report: IReportWarehouseProductsPairing[]) => ({
    payload: {
      report,
    },
  }),
);
