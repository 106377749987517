import { ReloadOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch, type UnknownAction } from 'redux';

type WarehouseProductFilterCallback = (
  minimalWeight: number,
  maximalWeight: number,
  minimalPrice: number,
  maximalPrice: number,
) => UnknownAction;

function WarehouseProductFilter({
  dispatch,
  cb,
}: {
  dispatch: Dispatch;
  cb: WarehouseProductFilterCallback;
}): JSX.Element {
  const [minimalWeight, setMinimalWeight] = useState<number>(0.001);
  const [maximalWeight, setMaximalWeight] = useState<number>(30);
  const [minimalPrice, setMinimalPrice] = useState<number>(0);
  const [maximalPrice, setMaximalPrice] = useState<number>(60000);

  return (
    <Space size={16}>
      <Space size={16} direction="vertical">
        <Input
          type="number"
          step="0.001"
          addonBefore="Minimal weight"
          value={minimalWeight}
          addonAfter="kg"
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setMinimalWeight(Number(event.target.value));
          }}
        />

        <Input
          type="number"
          step="0.001"
          addonBefore="Maximal weight"
          value={maximalWeight}
          addonAfter="kg"
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setMaximalWeight(Number(event.target.value));
          }}
        />
      </Space>

      <Space size={16} direction="vertical">
        <Input
          type="number"
          step="0.001"
          addonBefore="Minimal price"
          value={minimalPrice}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setMinimalPrice(Number(event.target.value));
          }}
        />

        <Input
          type="number"
          step="0.001"
          addonBefore="Maximal price"
          value={maximalPrice}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setMaximalPrice(Number(event.target.value));
          }}
        />
      </Space>

      <Space size={16} direction="vertical">
        <Button
          type="primary"
          onClick={(): void => {
            dispatch(
              cb(minimalWeight, maximalWeight, minimalPrice, maximalPrice),
            );
          }}
        >
          <ReloadOutlined />
        </Button>
      </Space>
    </Space>
  );
}

export default connect()(WarehouseProductFilter);
