import { currencyFormat } from '@mahawi/eshop-common/dist/src/currency-format';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import {
  getLowerCaseLanguageCode,
  getTranslation,
} from '@mahawi/eshop-common/dist/src/translation';
import {
  EFeatureFlag,
  type IBrand,
  type ICurrencyPrice,
  type ICurrencyType,
  type IFeatureFlag,
  type IProduct,
  type IProductEbay,
  type IProductFeatureFlag,
  type IWarehouse,
} from '@mahawi/eshop-common/dist/src/types';
import { Tag } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import React, { Key } from 'react';

import ExternalLink from '../components/external-link';
import Link from '../components/link';
import { type IConfigState } from '../reducers/config/types';
import { type ICurrencyState } from '../reducers/currency/types';
import { type IFeatureFlagsState } from '../reducers/feature-flags/types';
import { type ILanguageState } from '../reducers/language/types';
import { type IProductsState } from '../reducers/products/types';
import { coloredRenderOfNumberInTable } from './colored-render-of-number-in-table';
import { featureFlagTags } from './feature-flag-tags';

export interface IProductsTableColumnsProps {
  key: string;
  brand?: IBrand;
  name: string;
  gtin?: string;
  stock: number;
  mpn?: string;
  uuid: string;
  productsEbay: IProductEbay[];
  photoUUIDs: string[];
  relatedProductsCount: number;
  priceEUR?: number;
  primaryWarehouse?: IWarehouse;
  featureFlags: IProductFeatureFlag[];
}

export function getDataSourceForProductsTable({
  Products,
  Language,
}: {
  Products: IProductsState;
  Language: ILanguageState;
}): IProductsTableColumnsProps[] {
  if (!Products.products) {
    return [];
  }

  const dataSource: IProductsTableColumnsProps[] = Products.products.map(
    ({
      brand,
      gtin,
      stock,
      mpn,
      names,
      uuid,
      productsEbay,
      photoUUIDs,
      relatedProductsCount,
      prices,
      primaryWarehouse,
      featureFlags,
    }: IProduct): IProductsTableColumnsProps => {
      const productLocaNameLocalized: string = getTranslation(
        names,
        Language.languageType,
      );

      const price: ICurrencyPrice | undefined = prices.find(
        (p: ICurrencyPrice): boolean => p.isoCode === 'EUR',
      );

      return {
        key: uuid,
        brand,
        name: productLocaNameLocalized,
        gtin,
        stock,
        mpn,
        uuid,
        productsEbay,
        photoUUIDs,
        relatedProductsCount,
        priceEUR: price?.price,
        primaryWarehouse,
        featureFlags,
      };
    },
  );

  return dataSource;
}

export function getProductsTableColumns({
  dataSource,
  Config,
  Currency,
  Language,
  FeatureFlags,
}: {
  dataSource: IProductsTableColumnsProps[];
  Config: IConfigState;
  Currency: ICurrencyState;
  Language: ILanguageState;
  FeatureFlags: IFeatureFlagsState;
}): ColumnsType<object> {
  const currencyTypeEUR: ICurrencyType | undefined = Currency.currencies.find(
    (c: ICurrencyType): boolean => c.isoCode === 'EUR',
  );

  if (!currencyTypeEUR) {
    throw new Error('EUR currency not found');
  }

  const columnFilterItemPrimaryWarehouse: ColumnFilterItem[] = [
    ...new Set(
      dataSource.map(
        ({
          primaryWarehouse,
        }: IProductsTableColumnsProps): string | undefined =>
          primaryWarehouse?.name,
      ),
    ),
  ]
    .filter(notEmpty)
    .map((name: string) => ({
      text: name,
      value: name,
    }));

  columnFilterItemPrimaryWarehouse.push({
    text: 'With primary warehouse',
    value: 'withPrimaryWarehouse',
  });

  columnFilterItemPrimaryWarehouse.push({
    text: 'Without primary warehouse',
    value: 'withoutPrimaryWarehouse',
  });

  const columnFilterItemFeatureFlags: ColumnFilterItem[] = Object.values(
    EFeatureFlag,
  )
    .map((code: EFeatureFlag) => {
      const ff: IFeatureFlag | undefined = FeatureFlags.featureFlags?.find(
        (f: IFeatureFlag): boolean => f.code === code,
      );

      if (!ff) {
        return undefined;
      }

      return {
        text: ff.name,
        value: ff.code,
      };
    })
    .filter(notEmpty);

  columnFilterItemFeatureFlags.push({
    text: 'With feature flags',
    value: 'withFeatureFlags',
  });

  columnFilterItemFeatureFlags.push({
    text: 'Without feature flags',
    value: 'withoutFeatureFlags',
  });

  const columns: ColumnsType<object> = [
    {
      title: 'Brand',
      dataIndex: ['brand', 'name'],
      sorter: (
        a: IProductsTableColumnsProps,
        b: IProductsTableColumnsProps,
      ) => {
        if (a.brand?.name && b.brand?.name) {
          return a.brand.name.localeCompare(b.brand.name);
        }

        return 0;
      },
      filterSearch: true,
      filters: [
        ...new Set(
          dataSource.map(
            ({ brand }: IProductsTableColumnsProps): string | undefined =>
              brand?.name,
          ),
        ),
      ]
        .filter(notEmpty)
        .map((name: string) => ({
          text: name,
          value: name,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
      onFilter: (
        value: boolean | Key,
        record: IProductsTableColumnsProps,
      ): boolean => record.brand?.name === value,
      width: '5%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, record: IProductsTableColumnsProps): JSX.Element => (
        <Link to={`/product/${record.uuid}`} label={text} openInNewTab />
      ),
      sorter: (
        a: IProductsTableColumnsProps,
        b: IProductsTableColumnsProps,
      ): number => a.name.localeCompare(b.name),
      filterSearch: true,
      filters: [
        ...new Set(
          dataSource.map(({ name }: IProductsTableColumnsProps) => name),
        ),
      ]
        .map((name: string) => ({
          text: name,
          value: name,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
      onFilter: (
        value: boolean | Key,
        record: IProductsTableColumnsProps,
      ): boolean => record.name === value,
      width: '25%',
    },
    {
      title: 'Price EUR',
      dataIndex: 'priceEUR',
      sorter: (
        a: IProductsTableColumnsProps,
        b: IProductsTableColumnsProps,
      ): number => (a.priceEUR || 0) - (b.priceEUR || 0),
      render: (priceEUR: number | undefined): string =>
        priceEUR ? currencyFormat(priceEUR, currencyTypeEUR) : '---',
      filterSearch: true,
      filters: [
        {
          text: 'With price',
          value: 'withPrice',
        },
        {
          text: 'Without price',
          value: 'withoutPrice',
        },
      ],
      onFilter: (
        value: boolean | Key,
        record: IProductsTableColumnsProps,
      ): boolean => {
        if (value === 'withPrice') {
          return !!record.priceEUR;
        }

        if (value === 'withoutPrice') {
          return !record.priceEUR;
        }

        return false;
      },
      width: '10%',
    },
    {
      title: 'MPN',
      dataIndex: 'mpn',
      sorter: (
        a: IProductsTableColumnsProps,
        b: IProductsTableColumnsProps,
      ): number => `${a.mpn}`.localeCompare(`${b.mpn}`),
      filterSearch: true,
      filters: [
        ...new Set(
          dataSource.map(
            ({ mpn }: IProductsTableColumnsProps): string | undefined => mpn,
          ),
        ),
      ]
        .filter((mpn: string | undefined): mpn is string => !!mpn)
        .map((name: string) => ({
          text: name,
          value: name,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
      onFilter: (
        value: boolean | Key,
        record: IProductsTableColumnsProps,
      ): boolean => record.mpn === value,
      width: '10%',
    },
    {
      title: 'GTIN',
      dataIndex: 'gtin',
      sorter: (
        a: IProductsTableColumnsProps,
        b: IProductsTableColumnsProps,
      ): number => (a.gtin || '').localeCompare(b.gtin || ''),
      filterSearch: true,
      filters: [
        ...new Set(
          dataSource.map(
            ({ gtin }: IProductsTableColumnsProps): string | undefined => gtin,
          ),
        ),
      ]
        .filter(notEmpty)
        .map((name: string) => ({
          text: name,
          value: name,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
      onFilter: (
        value: boolean | Key,
        record: IProductsTableColumnsProps,
      ): boolean => record.gtin === value,
      width: '10%',
    },
    {
      title: 'Photos',
      dataIndex: 'photoUUIDs',
      sorter: (
        a: IProductsTableColumnsProps,
        b: IProductsTableColumnsProps,
      ): number => a.photoUUIDs.length - b.photoUUIDs.length,
      filterSearch: true,
      filters: [
        {
          text: 'With photos',
          value: 'withPhotos',
        },
        {
          text: 'Without photos',
          value: 'withoutPhotos',
        },
      ],
      onFilter: (
        value: boolean | Key,
        record: IProductsTableColumnsProps,
      ): boolean => {
        if (value === 'withPhotos') {
          return record.photoUUIDs.length > 0;
        }

        if (value === 'withoutPhotos') {
          return record.photoUUIDs.length === 0;
        }

        return false;
      },
      render: (photoUUIDs: string[]): number => photoUUIDs.length,
      width: '5%',
    },
    {
      title: 'Related products',
      dataIndex: 'relatedProductsCount',
      sorter: (
        a: IProductsTableColumnsProps,
        b: IProductsTableColumnsProps,
      ): number => a.relatedProductsCount - b.relatedProductsCount,
      filterSearch: true,
      filters: [
        {
          text: 'With related products',
          value: 'withRelatedProducts',
        },
        {
          text: 'Without related products',
          value: 'withoutRelatedProducts',
        },
      ],
      onFilter: (
        value: boolean | Key,
        record: IProductsTableColumnsProps,
      ): boolean => {
        if (value === 'withRelatedProducts') {
          return record.relatedProductsCount > 0;
        }

        if (value === 'withoutRelatedProducts') {
          return record.relatedProductsCount === 0;
        }

        return false;
      },
      render: coloredRenderOfNumberInTable,
      width: '5%',
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      sorter: (
        a: IProductsTableColumnsProps,
        b: IProductsTableColumnsProps,
      ): number => a.stock - b.stock,
      render: coloredRenderOfNumberInTable,
      filterSearch: true,
      filters: [
        {
          text: 'In stock',
          value: 'inStock',
        },
        {
          text: 'Full stock',
          value: 'fullStock',
        },
        {
          text: 'Low stock',
          value: 'lowStock',
        },
        {
          text: 'Out of stock',
          value: 'outOfStock',
        },
      ],
      onFilter: (
        value: boolean | Key,
        record: IProductsTableColumnsProps,
      ): boolean => {
        if (value === 'inStock') {
          return record.stock > 0;
        }

        if (value === 'fullStock') {
          return record.stock >= 5;
        }

        if (value === 'lowStock') {
          return record.stock > 0 && record.stock < 5;
        }

        if (value === 'outOfStock') {
          return record.stock === 0;
        }

        return false;
      },
      width: '5%',
    },
    {
      title: 'Primary warehouse',
      dataIndex: 'primaryWarehouse',
      sorter: (
        a: IProductsTableColumnsProps,
        b: IProductsTableColumnsProps,
      ): number =>
        a.primaryWarehouse?.name.localeCompare(
          b.primaryWarehouse?.name || '',
        ) || 0,
      filterSearch: true,
      filters: columnFilterItemPrimaryWarehouse,
      onFilter: (
        value: boolean | Key,
        record: IProductsTableColumnsProps,
      ): boolean => {
        if (value === 'withoutPrimaryWarehouse') {
          return !record.primaryWarehouse;
        }

        if (value === 'withPrimaryWarehouse') {
          return !!record.primaryWarehouse;
        }

        return record.primaryWarehouse?.name === value;
      },
      render: (primaryWarehouse: IWarehouse | undefined): string => {
        return primaryWarehouse ? `${primaryWarehouse.name}` : '---';
      },
      width: '10%',
    },
    {
      title: 'Feature flags',
      dataIndex: 'featureFlags',
      sorter: (
        a: IProductsTableColumnsProps,
        b: IProductsTableColumnsProps,
      ): number => a.featureFlags.length - b.featureFlags.length,
      filterSearch: true,
      filters: columnFilterItemFeatureFlags,
      onFilter: (
        value: boolean | Key,
        record: IProductsTableColumnsProps,
      ): boolean => {
        if (value === 'withFeatureFlags') {
          return record.featureFlags.length > 0;
        }

        if (value === 'withoutFeatureFlags') {
          return record.featureFlags.length === 0;
        }

        return record.featureFlags.some(
          (productFeatureFlag: IProductFeatureFlag): boolean => {
            return productFeatureFlag.featureFlag === value;
          },
        );
      },
      render: (productFeatureFlags: IProductFeatureFlag[]): JSX.Element[] =>
        featureFlagTags(productFeatureFlags, FeatureFlags),
      width: '5%',
    },
    {
      title: 'Ebay',
      dataIndex: 'productsEbay',
      sorter: (
        a: IProductsTableColumnsProps,
        b: IProductsTableColumnsProps,
      ): number => a.productsEbay.length - b.productsEbay.length,
      render: (productsEbay: IProductEbay[]): JSX.Element[] =>
        productsEbay.map(
          (productEbay: IProductEbay): JSX.Element => (
            <Tag key={productEbay.marketplace.code}>
              {productEbay.marketplace.name}
            </Tag>
          ),
        ),
      filterSearch: true,
      filters: [
        {
          text: 'With ebay',
          value: 'withEbay',
        },
        {
          text: 'Without ebay',
          value: 'withoutEbay',
        },
      ],
      onFilter: (
        value: boolean | Key,
        record: IProductsTableColumnsProps,
      ): boolean => {
        if (value === 'withEbay') {
          return record.productsEbay.length > 0;
        }

        if (value === 'withoutEbay') {
          return record.productsEbay.length === 0;
        }

        return false;
      },
      width: '5%',
    },
    {
      title: 'Actions',
      render: (_text, record: IProductsTableColumnsProps): JSX.Element => (
        <ExternalLink
          to={`${
            Config.url
          }/${getLowerCaseLanguageCode(Language.languageType)}/product/${record.uuid}`}
          ariaLabel="Open product in new tab"
        />
      ),
      width: '5%',
    },
  ];

  return columns;
}
