import { EnvironmentOutlined } from '@ant-design/icons';
import { type IAddress } from '@mahawi/eshop-common';
import React from 'react';

export default function GoogleMapsButton({
  address,
}: {
  address: IAddress;
}): JSX.Element {
  const url: string = `https://www.google.com/maps/search/${encodeURIComponent(
    `${address.street}, ${address.city}, ${address.zip}, ${address.countryTypeIsoCode}`,
  )}`;

  return (
    <EnvironmentOutlined
      style={{ color: '#61B1FF' }}
      onClick={(): void => {
        window.open(url, '_blank', 'noopener,noreferrer');
      }}
    />
  );
}
