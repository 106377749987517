import { type IAdminCategory } from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { CATEGORY_ACTION } from './types';

export const categoryLoad = createAction(
  CATEGORY_ACTION.LOAD,
  (categoryUUID: string) => ({
    payload: { category: { uuid: categoryUUID } },
  }),
);

export const categoryLoadResponse = createAction(
  CATEGORY_ACTION.LOAD_RESPONSE,
  (category: IAdminCategory | null) => ({
    payload: { category },
  }),
);

export const nameUpdate = createAction(
  CATEGORY_ACTION.UPDATE_NAME,
  (categoryUUID: string, name: string, languageTypeCode: string) => ({
    payload: {
      category: { uuid: categoryUUID },
      languageType: { code: languageTypeCode },
      name,
    },
  }),
);

export const nameUpdateResponse = createAction(
  CATEGORY_ACTION.UPDATE_NAME_RESPONSE,
  (category: IAdminCategory | null) => ({
    payload: { category },
  }),
);

export const nameLive = createAction(
  CATEGORY_ACTION.NAME_LIVE,
  (name: string, languageTypeCode: string) => ({
    payload: { languageType: { code: languageTypeCode }, name },
  }),
);

export const savePropertyCodes = createAction(
  CATEGORY_ACTION.SAVE_PROPERTY_LIST_CODES,
  (categoryUUID: string, propertyCodes: string[]) => ({
    payload: {
      category: { uuid: categoryUUID },
      property: { codes: propertyCodes },
    },
  }),
);

export const categorySavePosition = createAction(
  CATEGORY_ACTION.SAVE_POSITION,
  (categoryUUID: string, position: number) => ({
    payload: { category: { uuid: categoryUUID }, position },
  }),
);

export const categorySavePositionResponse = createAction(
  CATEGORY_ACTION.SAVE_POSITION_RESPONSE,
  (saved: boolean) => ({
    payload: { saved },
  }),
);

export const categorySavechildCategories = createAction(
  CATEGORY_ACTION.SAVE_SUB_CATEGORIES,
  (categoryUUID: string, childCategoriesUUIDs: string[]) => ({
    payload: {
      category: { uuid: categoryUUID },
      childCategories: { uuids: childCategoriesUUIDs },
    },
  }),
);

export const categorySavechildCategoriesResponse = createAction(
  CATEGORY_ACTION.SAVE_SUB_CATEGORIES_RESPONSE,
  (category: IAdminCategory | null) => ({
    payload: { category },
  }),
);
